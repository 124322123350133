import { useContext, useEffect, useState } from "react"
import { FormContextProvider } from "context/FormContext"
import { ApplicationDataParser } from "components/ApplicationDataParser"
import { generateValidationSchema } from "utilities/schema"
import { UserContext } from "context/UserContext"
import { AuthContext } from "context/AuthContext"
import { TealiumLinkTrack } from "tealium"
import { lessThanOneMinuteAgo } from "utilities/helpers"
import { ClientContext } from "context/ClientContext"
import { IS_PRODUCTION } from "utilities/constants"
import { useLogFormJSON, useLogInitialValues } from "hooks"
import { ApplicationData } from "types"
import { LayoutProvider } from "./LayoutProvider"
import { OptionalLabelManager } from "context/OptionalLabelContext"

export const Authenticated = (localAppData: ApplicationData) => {
  const { token } = useContext(AuthContext)
  const { user } = useContext(UserContext)
  const { client } = useContext(ClientContext)
  const [validationSchema, setValidationSchema] = useState<any>()
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false)

  const applicationData = IS_PRODUCTION ? client.data : localAppData
  const { applicationData: initialFormValues } = user

  useLogFormJSON(applicationData)
  useLogInitialValues(initialFormValues)

  useEffect(() => {
    async function setFaviconAndPageTitle() {
      const favicon: HTMLLinkElement = document.querySelector("#favicon")
      if (applicationData.meta && favicon) {
        favicon.href = applicationData.meta.favicon
        document.title = `${applicationData.meta.title}`
      }
    }
    setFaviconAndPageTitle()
  }, [applicationData.meta])

  useEffect(
    function generateValidations() {
      if (client && !validationSchema) {
        const validations = generateValidationSchema(applicationData)
        setValidationSchema(validations)
      }
    },
    [applicationData, client, validationSchema],
  )

  useEffect(
    function setDataLoadedAndNewUser() {
      if (client && validationSchema) {
        setIsDataLoaded(true)
        if (lessThanOneMinuteAgo(user.createdAt) && typeof window !== 'undefined' && 'utag' in window) {
          TealiumLinkTrack({
            tealium_event: "app_start", 
            email_address: user.email,
          })
        }
      }
    },
    [client, user, validationSchema],
  )

  return (
    <FormContextProvider
      validationSchema={validationSchema}
      user={user}
      token={token}
    >
      <LayoutProvider
        applicationData={applicationData}
        isDataLoaded={isDataLoaded}
      >
        <OptionalLabelManager>
          <ApplicationDataParser {...applicationData} />
        </OptionalLabelManager>
      </LayoutProvider>
    </FormContextProvider>
  )
}
